import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route,Link,Routes} from 'react-router-dom'


import Home from './components/homevvex'


const App =() =>{
  return(
  <>
    <Home></Home>
  </>
  )
}
  
export default App;
