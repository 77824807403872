import React from "react";
import './index.scss'

import Fondo from '../img/fondo.jpg'
import Logo from '../img/Vine.png'

import LogoFooter from '../img/Vine.png'


import Whats from '../img/whatsapp.svg'
import Face from '../img/meta.svg'
import Link from '../img/linkedin.svg'
import Insta from '../img/instagram.svg'
import Video from './vvx.mp4'


const Home = () =>{
    return(
        <div className="HomeContainer" >




            <div className="logo">
                <img src={Logo}></img>
            </div>

{
/**
             <div class="lds-default">               
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
               
            </div>
 */
}
           
            <div className="construccion">
                
            <video src={Video} muted autoPlay loop />            </div>



            <div className="descriptionBrand">

                SI HABLAMOS DE EXPERIENCIAS DE LUJO, HABLAMOS DE VINE VERA EXPERIENCE, TODO CON EL FIN DE TRANSFORMAR LA
                APARIENCIA Y RÉGIMEN DE CUIDADO DE LA PIEL. MÁS DE 10 TECNOLOGÍAS Y 50 PRODUCTOS CON RESVERATROL ACTIVO, 
                CONOCIDO COMO LA FUENTE DE LA JUVENTUD, VINE VIRE SE DESTACA POR SU EXCLUSIVO Y 
                VANGUARDISTA SERVICIO.
                
            </div>

            <div className="footer">
                <div className="LogoFooter">
                <img src={LogoFooter}></img>
                </div>
                <div className="LogosIconos">             
                    <a href='https://api.whatsapp.com/send/?phone=5568186633&text&app_absent=0' target="_blank">
                        <img src={Whats} ></img>
                    </a>
                    <a href='https://www.facebook.com/Vvexperiencemx/' target="_blank">
                        <img src={Face}></img>
                    </a>
                    {/*                   
                    <a href='https://www.linkedin.com/company/ftk-beauty-group/mycompany/' target="_blank">
                        <img src={Link}></img>
                    </a>
                    */}
                    <a href='https://instagram.com/vvexperiencemx?igshid=NGVhN2U2NjQ0Yg==' target="_blank">
                        <img src={Insta}></img>
                    </a>             
                </div>








                <div className="direction">
                    <a href="https://goo.gl/maps/zWDk2CK87yBfXDmZ9" target="_blank">
                        Calle Arquímedes 199, Piso 2, Polanco V Secc, 
                        Miguel Hidalgo, CDMX.
                    </a>
                </div>

                <div className="directionMovil">
                    <a href="https://goo.gl/maps/zWDk2CK87yBfXDmZ9" target="_blank">
                        Calle Arquímedes 199, Piso 2, Polanco, CDMX.
                    </a>
                </div>
            </div>

        </div>
)}


export default Home;